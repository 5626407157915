import { ChangeEvent } from "react";

import { Document } from "../../../../backend/common/request.types";
import { uploadFiles } from "../services/request.service";

import { uploadFile } from "./firebaseStorage";
import { convertImageIfHeic } from "./utils";

export const imageTypesForUpload = {
  "image/apng": [".apng"],
  "image/bmp": [".bmp"],
  "image/gif": [".gif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "image/tiff": [".tiff"],
  "image/webp": [".webp"],
  "image/heic": [".heic"],
};

export const imageTypesForUploadInput =
  Object.keys(imageTypesForUpload).join(",");

export const documentTypesForUpload = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  ".awf": [".awf"],
  "application/zip": [".zip"],
  "video/*": [".mp4", ".webm", ".ogg"],
  "audio/*": [".mp3", ".wav", ".ogg"],
};

const documentTypesForUploadInput = Object.keys(documentTypesForUpload).join(
  ","
);

export const allAllowedFileTypesForUploadInput = `${imageTypesForUploadInput},${documentTypesForUploadInput}`;

type UploadImageType = {
  imageUrl: string;
  urlObject: string;
  blob: File;
};

type UploadDocumentType = {
  document: Document;
  fileObject: File;
};

type UploadResult =
  | {
      images?: { imageUrl: string }[];
      documents?: Document[];
      note?: string | null;
      errors?: string[];
    }
  | undefined;

export const uploadAttachedFiles = async (
  e: ChangeEvent<HTMLInputElement>,
  requestId: number | undefined,
  channelId: number | undefined | null
): Promise<UploadResult> => {
  if (!requestId || !channelId || !e.target.files?.length) return;

  const filesArray = Array.from(e.target.files);
  const uploadImages: UploadImageType[] = [];
  const uploadDocuments: UploadDocumentType[] = [];
  const errors: string[] = [];

  for (const file of filesArray) {
    if (file.type.includes("image")) {
      const convertedFile = await convertImageIfHeic(file);
      if ("error" in convertedFile) {
        errors.push(convertedFile.error);
        continue;
      }
      uploadImages.push({
        imageUrl: file.name,
        urlObject: URL.createObjectURL(file),
        blob: convertedFile,
      });
    } else {
      uploadDocuments.push({
        document: {
          url: "",
          name: file.name,
          fileType: file.type,
          sizeInBytes: file.size,
        },
        fileObject: file,
      });
    }
  }

  const imageResults = await Promise.all(
    uploadImages.map(
      async (image) =>
        await uploadFile(image.blob, `request-data/${requestId}/images/`)
    )
  );

  const documentResults = await Promise.all(
    uploadDocuments.map(
      async (doc) =>
        await uploadFile(doc.fileObject, `request-data/${requestId}/documents/`)
    )
  );

  const images = imageResults.flatMap((result) => {
    if (result.url) {
      return [{ imageUrl: result.url }];
    } else {
      return [];
    }
  });

  const documents = documentResults.flatMap((result, index) => {
    if (result.url) {
      return [
        {
          ...uploadDocuments[index].document,
          url: result.url,
        },
      ];
    } else {
      return [];
    }
  });

  const response = await uploadFiles(channelId, {
    images,
    documents,
    note: null,
  });

  return { ...response.data, errors };
};
