import { useTheme } from "@mui/material";

import StyledLink from "../StyledLink";

const NavbarLink: React.FunctionComponent<{
  href: string;
  text: string;
  currentPath: string;
}> = ({ href, text, currentPath }) => {
  const isActive = href.split("?")[0] === currentPath.split("?")[0];
  const theme = useTheme();
  const borderBottom = isActive
    ? `4px solid ${theme.palette.accent1.main}`
    : "none";

  return (
    <StyledLink
      href={href}
      sx={{
        fontSize: 18,
        textDecoration: "none",
        borderBottom,
        color: "secondary.main",
      }}
    >
      {text}
    </StyledLink>
  );
};

export default NavbarLink;
